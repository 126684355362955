<template>
	<div>
		<CCard class="p-4"> 
			<CCardHeader>
				<CRow>
			
					<CCol lg="6">
						<h5>Material Transfer / {{dataParams.booking.booking_no}} / {{dataParams.mts_no}}</h5>
					</CCol> 
					<CCol lg="6">
			      		<div style="text-align: right"> 
			      			<CButton size="sm" class="px-3" color="btn btn-info" @click="print"><i class="fa fa-print"></i> Print</CButton> &nbsp; 
			      			<CButton size="sm" class="px-3" color="btn btn-outline-danger" @click="backToTable()"><i class="fa fa-close"></i>Close</CButton> &nbsp; 
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
			<CCardBody>
		<fieldset class="fieldset full-line">
			<legend> MTS Information</legend>
					
					<CRow>
						
						<CCol lg="6">
							Dept / Proj: <strong>{{dataParams.dept ? dataParams.dept.customer_name : "N/A"}}</strong>
						</CCol>
						<CCol lg="6">
							Transfer Date: <strong>{{dataParams.transfer_date ? moment.unix(dataParams.transfer_date).format('MMMM DD, YYYY') : "N/A"}}</strong>
						</CCol>  
					</CRow>
					<CRow> 
						<CCol lg="6">
							Origin: <strong>{{dataParams.origin ? dataParams.origin.customer_name : "N/A"}}</strong>
						</CCol>
						<CCol lg="6">
							Destination: <strong>{{dataParams.destination ? dataParams.destination.customer_name : "N/A"}}</strong>
						</CCol> 
						 
					</CRow> 
					<CRow>
						<!-- <CCol lg="2"></CCol> -->
						<CCol lg="6">
							Ref No.: <strong>{{dataParams.ref_no ? dataParams.ref_no : "N/A"}}</strong>
						</CCol> 

						<CCol lg="6">
							Plate No: <strong>{{bookingParams.vehicle ? bookingParams.vehicle.plate_no : "N/A"}}</strong>
						</CCol> 
	 
					</CRow>
					<CRow >
						<CCol lg="6"> 
						</CCol> 
						
						<CCol lg="6">
							Driver: <strong>{{bookingParams.driver ? bookingParams.driver.name : "N/A"}}</strong>
						</CCol> 
					</CRow>
					
		</fieldset>

		<fieldset class="fieldset full-line">
			<legend> MTS Items</legend>
					<CRow >
		        		<CCol lg="12">
		        			<CDataTable
						      	:items="dataParams.material_transfer_item"
						      	:fields="[
								  {
										key: 'asset_type_view', 
										label: 'Asset Type'
									},
									{
										key: 'no', 
										label: 'Asset No.'
									},
									{
										key: 'qty', 
										label: 'Qty'
									},
									{
										key: 'unit', 
										label: 'Unit'
									},
									{
										key: 'particular', 
										label: 'Particular'
									},
									{
										key: 'serial_no_view', 
										label: 'Serial No.'
									},
									{
										key: 'brand_view', 
										label: 'Brand'
									}
								]"
								hover
								striped
								border
								small
								fixed
								custom
						    >   
						    </CDataTable> 
							</CCol> 
					<CCol lg="12">
							Remarks: <strong>{{dataParams.remarks ? dataParams.remarks : "N/A"}}</strong>
					</CCol>
		       
		        	</CRow>    
		</fieldset>
		<fieldset class="fieldset full-line">
			<legend>References</legend>
	
					<CRow >
						
						<CCol lg="6">
							Prepared By: <strong>{{dataParams.prepared_by ? dataParams.prepared_by : "N/A"}}</strong>
						</CCol>
						<CCol lg="6">
							Approved By: <strong>{{dataParams.approved_by ? dataParams.approved_by : "N/A"}}</strong>
						</CCol> 
					</CRow>
		</fieldset>	
			</CCardBody> 
		</CCard> 
		<Print :dataParams="dataParams" :bookingParams="bookingParams"/>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import '../../style.css';
import moment from 'moment'; 
import Print from './print';

export default {
	mounted(){  

		this.getData(); 
		this.getBooking();

	},
	data(){
		return{    
			dataList: [],  
			bookingParams: {
				booking_no: "", 
				plate_no:"",
				driver_id:"",
				driver: {
					name: ""
				},
				vehicle: {
					plate_no: ""
				}  
			},

			dataParams: {
			
				booking_no:"",
				dept_id: "",
				origin_id: "",
				destination_id: "",
				transfer_date: "",
				remarks: "",
				ref_no: "",
				prepared_by: "",
				approved_by: "",
				mts_no: "",
				material_transfer_item: [
					{
						no: "",
						qty: "",
						unit: "units",
						particular: ""
					}
				],
				booking: {
					booking_no: ""
				}
			},
			editMode: false, 
			vendorList: {
				data: []
			},
			vehicleTypeList: {
				data: []
			},
			makerModelList: {
				data: []
			},
			depotList: {
				data: []
			},
			originList: {
				data: []
			},
			isLoading: false,
			uploadPercentage: 0,
			config,
			moment
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, Print},
	methods: { 

		getData(){
			this.$showLoading(true)
			axios.get(config.api_path+"/materialTransfer/"+this.$route.params.mts_id)
			.then(response=>{ 
				this.$showLoading(false) 
				this.dataParams = response.data.data;  
				this.dataParams.material_transfer_item = this.dataParams.material_transfer_item.map((value, index)=>{
					value.asset_type_view = value.asset_type ;
					if(value.asset_type == "" || value.asset_type == null){
						value.asset_type_view = "N/A";
					}
					value.serial_no_view = value.serial_no ;
					if(value.serial_no == "" || value.serial_no == null){
						value.serial_no_view = "N/A";
					}
					value.brand_view = value.brand ;
					if(value.brand == "" || value.brand == null){
						value.brand_view = "N/A";
					}
					return value;
				})
			})

		},   

	    backToTable(){
	    	this.$router.push('/booking/'+this.$route.params.id+'/mts')
	    }, 

	    updateModal(item){
	    	this.$router.push('/main/update_booking/'+item.id)
	    },

	    viewDr(item){
	    	this.$router.push('/main/view_booking/'+item.id+"/dr")
	    },

	    getBooking(){
			axios.get(config.api_path+"/booking/"+this.$route.params.id)
			.then(response=>{  
				this.bookingParams = response.data.data;   
			})
		}, 

		print(){

	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('mts_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    },

 	}
}
</script>
