<template>
	<div id="mts_print" style="display: none">
		<h4>MTS Information</h4>
		<table>
			<tr>
				<td width="400">
					Booking No: <strong>{{dataParams.booking.booking_no}}</strong>
				</td>
				<td width="400">
					MTS No: <strong>{{dataParams.mts_no}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="400">
					Dept / Proj: <strong>{{dataParams.dept ? dataParams.dept.customer_name : "N/A"}}</strong>
				</td>
				<td width="400">
					Transfer Date: <strong>{{dataParams.transfer_date ? moment.unix(dataParams.transfer_date).format('MMMM DD, YYYY') : "N/A"}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="400">
					Origin: <strong>{{dataParams.origin ? dataParams.origin.customer_name : "N/A"}}</strong>
				</td>
				<td width="400">
					Destination: <strong>{{dataParams.destination ? dataParams.destination.customer_name : "N/A"}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="400">
					Ref No: <strong>{{dataParams.ref_no ? dataParams.ref_no : "N/A"}}</strong>
				</td>
				<td width="400">
					Plate No: <strong>{{bookingParams.vehicle ? bookingParams.vehicle.plate_no : "N/A"}}</strong>
				</td> 
			</tr>
			<tr>
				<td width="400">
					&nbsp;
				</td>
				<td width="400">
					Driver: <strong>{{bookingParams.driver ? bookingParams.driver.name : "N/A"}}</strong>
				</td> 
			</tr>
		</table>
		<hr/> 
		<h4>MTS Items</h4>
		<table border="1">
			<tr style="-webkit-print-color-adjust:exact; background-color: black">
				<td width="225" style="color: white">
					Asset Type
				</td>
				<td width="225" style="color: white">
					Asset No
				</td>
				<td width="225" style="color: white">
					Qty
				</td>
				<td width="225" style="color: white">
					Unit
				</td>
				<td width="225" style="color: white">
					Particular
				</td>
				<td width="225" style="color: white">
					Serial No.
				</td>
				<td width="225" style="color: white">
					Brand
				</td>
			</tr> 
			<tr v-for="(item, index) in dataParams.material_transfer_item">
				<td width="225">
					<strong>{{item.asset_type}}</strong>
				</td>
				<td width="225">
					<strong>{{item.no}}</strong>
				</td>
				<td width="225">
					<strong>{{item.qty}}</strong>
				</td>
				<td width="225">
					<strong>{{item.unit}}</strong>
				</td>
				<td width="225">
					<strong>{{item.particular}}</strong>
				</td>
				<td width="225">
					<strong>{{item.serial_no}}</strong>
				</td>
				<td width="225">
					<strong>{{item.brand}}</strong>
				</td>
			</tr>
		</table>
<br>
			<div >
				Remarks: <strong>{{dataParams.remarks? dataParams.remarks : "N/A"}}</strong>
			</div>
					
			
		<br/>
		<hr/>
		<br/>
		<h4>References</h4>
		<br><br>
		<table>
			<tr>
				<td width="400">
					Prepared By: <strong>{{dataParams.prepared_by ? dataParams.prepared_by : "N/A"}}</strong>
				</td>
				<td width="400">
					Approved By: <strong>{{dataParams.approved_by ? dataParams.approved_by : "N/A"}}</strong>
				</td> 
			</tr> 
		</table> 
	</div>
</template>
<script>
	import moment from 'moment'; 
	export default {
		mounted(){   

		},
		data(){
			return{
				moment
			}
		},
		props: {
			dataParams: {
				booking_no:"",
				dept_id: "",
				origin_id: "",
				destination_id: "",
				transfer_date: "",
				remarks: "",
				ref_no: "",
				prepared_by: "",
				approved_by: "",
				mts_no: "",
				material_transfer_item: [
					{
						no: "",
						qty: "",
						unit: "units",
						particular: ""
					}
				],
				booking: {
					booking_no: ""
				}
			},
			bookingParams: {
				booking_no: "", 
				plate_no:"",
				driver_id:"",
				driver: {
					name: ""
				},
				vehicle: {
					plate_no: ""
				}  
			},
		},
	}
</script>